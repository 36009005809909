@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* --font-playfair: 'Playfair Display', 'serif'; */

* {
  font-family: "Source Sans Pro", "sans-serif";
}
.app {
  width: 100%;
}
.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  z-index: 2;
  position: relative;
  @media only screen and (min-width: 996px) {
    padding: 10px 40px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.header-logo-srb {
  height: 50px;
  width: auto;
}
.header-logo-eng {
  height: 50px;
  width: auto;
}
.lang-change-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
  @media only screen and (min-width: 996px) {
    margin-top: 0;
  }
}
.lang-btn {
  font-size: 16px;
  width: 50px;
  text-align: center;
  color: #063779;
  font-weight: 600;
}
.lang-btn:hover {
  cursor: pointer;
  font-style: italic;
}
.lang-btn-is-selected {
  font-style: italic;
  color: #e11b21;
}
.srb-btn {
  border-right: 2px solid gray;
}
main {
  width: 100%;
  max-width: 1440px;
  margin: 20px auto;
}
.main-title-wrapper {
  width: 100%;
  padding: 0 40px;
  position: relative;
}
.background-image {
  width: 100%;
  max-width: 1440px;
  height: 430px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  @media only screen and (min-width: 400px) {
    height: 380px;
  }
  @media only screen and (min-width: 555px) {
    height: 320px;
  }
  @media only screen and (min-width: 720px) {
    height: 275px;
  }
  @media only screen and (min-width: 996px) {
    height: 320px;
  }
}
h1 {
  color: #063779;
  font-size: 24px;
  line-height: 1.35;
  font-weight: 400;
  font-family: "Playfair Display", "serif";
  @media only screen and (min-width: 996px) {
    font-size: 32px;
  }
}
.form-wrapper {
  z-index: 2;
  width: 83%;
  max-width: 644px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 35px 35px 50px 35px;
  box-shadow: 0 3px 46px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}
label {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  margin-bottom: 10px;
  padding-left: 5px;
  @media only screen and (min-width: 996px) {
    font-size: 16px;
  }
}
input {
  width: 100%;
  height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f7f7f7;
  border: 0;
  outline: 0;
  margin-bottom: 0;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  padding-right: 20px;
  @media only screen and (min-width: 996px) {
    font-size: 16px;
  }
}
select {
  width: 100%;
  height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f7f7f7;
  border: 0;
  outline: 0;
  margin-bottom: 0;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  padding-right: 20px;
  margin-bottom: 26px;
  @media only screen and (min-width: 996px) {
    font-size: 16px;
  }
}
.error-msg {
  margin: 0;
  color: #e11b21;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  height: 30px;
  @media only screen and (min-width: 996px) {
    font-size: 14px;
  }
}
button {
  background-color: #96d370;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 45px;
  margin: 30px auto 0 auto;
  border-radius: 30px;
  border: 0;
  outline: 0;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  @media only screen and (min-width: 996px) {
    font-size: 16px;
  }
}
button:hover {
  font-weight: 600;
}
.disabled-btn {
  cursor: not-allowed;
}
.disabled-btn:hover {
  font-weight: 400;
}
.result-list {
  width: 100%;
  margin-top: 40px;
}
.result-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  border-bottom: 1px solid grey;
  padding-left: 10px;
  padding-right: 10px;
}
.result-list-item:hover .list-item {
  font-weight: 600;
}
.list-item {
  font-size: 14px;
  @media only screen and (min-width: 996px) {
    font-size: 16px;
  }
}

.list-item-limited{
  max-width: 75%;
  text-align: end;
}

.text-wrapper {
  z-index: 2;
  width: 83%;
  max-width: 644px;
  margin: 60px auto;
  color: #063779;
}
.disclaimer-msg {
  padding-left: 5px;
  font-size: 14px;
  margin-bottom: 20px;
  color: #063779;
  @media only screen and (min-width: 996px) {
    font-size: 16px;
  }
}
.error-input {
  border: 2px solid red;
}
.search-wrapper {
  position: relative;
}
.search-results-wrapper {
  position: absolute;
  background-color: #fff;
  padding: 0 10px;
  top: 62px;
  border-radius: 6px;
  max-height: 190px;
  overflow-y: auto;
  width: 100%;
}
.search-result-list-item {
  list-style-type: none;
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid #063779;
  line-height: 25px;
}
.search-result-list-item:hover{
  cursor: pointer;
  background-color: #f7f7f7;
}
.search-result-code{
  margin-right: 5px;
  padding-right: 5px;
  border-right: 1px solid grey;
  align-self: stretch;
}